import MyTheme from '../../../themes/MyTheme';
import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { createTheme } from '@mui/material/styles';
import { responsiveFontSizes } from '@mui/material/styles';

let theme = createTheme();
theme = responsiveFontSizes(theme);

function CaseStudyIntro() {
  return (
    <ThemeProvider theme={MyTheme}>
      <ThemeProvider theme={theme}>
        <div>
          <span className='learn-about-targeting-container'>
            <Typography
              variant='h3'
              align='center'
            style={{
              margin: 'auto',
              padding: '1.5em',
              overflowWrap: 'break-word',
              fontSize: '1.75em',
              fontFamily: 'Roboto, sans-serif',
              fontWeight: '900',
              textShadow: "2px 2px 1px black",
            }}>
              White Paper Intro
            </Typography>
          </span>
        </div>
      </ThemeProvider>
    </ThemeProvider>
  )
}

export default CaseStudyIntro;
