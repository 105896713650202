import MyTheme from '../../../themes/MyTheme';
import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import { responsiveFontSizes } from '@mui/material/styles';

let theme = createTheme();
theme = responsiveFontSizes(theme);

function CaseStudySummary3() {
  return (
    <ThemeProvider theme={MyTheme}>
      <ThemeProvider theme={theme}>
		    <div className='text-container-cs'
  	    	style={{
  	    	  textAlign: 'center',
  	    	  fontFamily: 'Roboto, sans-serif',
  	    	  fontSize: '1.015em',
            fontWeight: '400',
            color: '#0d47a1',
  	    	  margin: 'auto',
  	    	  padding: 'auto',
  	    	  width: '80%',
		    	}}>
		  		Before anything starts, the predictive target list is generated with information regarding the target audience, user demographic and perceived competition.
        </div>
      </ThemeProvider>
    </ThemeProvider>
	)
}

export default CaseStudySummary3;
