import './Footer.css';

import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import React from 'react';

const Footer = () => (
  <Hidden only={['xs', 'sm']}>
  <div className='footer'>
    <Grid>
    <div className='container'
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'center',
        height: '100%',
        }}>
      <h6 style={{
        fontSize: '1rem',
        fontWeight: '400',
        textAlign: 'center',
        paddingRight: '1.25em',
        }}>
          Appfluencer® is a Registered Trademark of Shared2you, Inc. Copyright © 2012-
          {new Date().getFullYear()}
            {'. All Rights Reserved.'}</h6>
          <h6 style={{
            fontSize: '1rem',
            fontWeight: '400',
            textAlign: 'center'
            }}>
          <a style={{ color: 'blue' }} href='/privacy'>Privacy</a>
          </h6>
          <br></br><br></br>
          <h6 style={{
            fontSize: '1rem',
            fontWeight: '400',
            paddingLeft: '1.25em',
            }}>
          <a style={{ color: 'blue' }} href='/terms'>Terms</a>
          </h6>
        </div>
      </Grid>
    </div>
  </Hidden>
);

export default Footer;
