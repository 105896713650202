import LearnMoreButton1abt from '../../Buttons/LearnMoreButton1abt';
import MyTheme from '../../../themes/MyTheme';
import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { createTheme } from '@mui/material/styles';
import { responsiveFontSizes } from '@mui/material/styles';

let theme = createTheme();
theme = responsiveFontSizes(theme);

function HowWeDoPredictiveContainer() {
  return (
    <ThemeProvider theme={MyTheme}>
      <ThemeProvider theme={theme}>
        <div className='how-we-dopred-container'>
          <Typography
            variant='h4'
            align='center'
            alignItems='center'
            style={{
              justify: 'center',
              textAlign: 'center',
              margin: 'auto',
              padding: 'auto',
              fontFamily: 'Roboto, sans-serif',
              fontSize: '1em',
              width: '100%',
            }}>
            All mobile advertisers want their marketing message to reach it's desired audience, be viewed by the people most likely to take action, and increase revenue. Appfluencer's Predictive Targeting does just that.
            <br /><br />
            Using its Predictive Technology, Appfluencer works with advertisers to achieve their goals by generating a target list of mobile apps used by consumers who are most likely to have an interest in the advertiser's product or service.
            <br /><br />
            Predictive Targeting is used to get your advertising message in front of specific users instead of sending it to all users. This ensures the users most likely to be interested, will see the marketing message and take action.
            <br /><br />
            <LearnMoreButton1abt />
          </Typography>
        </div>
      </ThemeProvider>
    </ThemeProvider>
  )
}

export default HowWeDoPredictiveContainer;
