import 'react-toastify/dist/ReactToastify.css';

import ContactFormIntro from './ContactFormIntro';
import ContactUs from './ContactForm/ContactUs';
// import { createTheme } from '@mui/material/styles';
// import { responsiveFontSizes } from '@mui/material/styles';
// import MyTheme from '../themes/myTheme';
import React from 'react';

// import { ThemeProvider } from '@mui/material/styles';

// const theme = createTheme();
// theme = responsiveFontSizes(theme);

export default function ContactUsContainer(){
  return (
    <div type='container'
      className='free-trial-container'
      align='center'
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        width: '60%',
        margin: 'auto',
        padding: '1em',
      }}
    >
      <ContactFormIntro />
      <ContactUs />
    </div>
  );
}
