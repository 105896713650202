import { ThemeProvider } from '@mui/material/styles/';
import Typography from '@mui/material/Typography';
import { createTheme } from '@mui/material/styles';
import { responsiveFontSizes } from '@mui/material/styles';

let theme = createTheme();
theme = responsiveFontSizes(theme);
function TakeActionContainer() {
  return (
    <ThemeProvider theme={theme}>
		  <div className='call-to-action-container'
  	  	style={{
  	  	  display: 'flex',
  	  	  justifyContent: 'center',
  	  	  textAlign: 'center',
  	  	  alignItems: 'center',
          margin: 'auto',
          padding: '2em',
  	  	}}>
			<Typography
        variant='h4'
        align='center'
          style={{
            color: "ghostwhite",
            margin: 'auto',
            padding: 'auto',
            fontFamily: "Roboto, sans-serif",
            textShadow: "2px 2px 1px black",
            justifyContent: 'center',
            textAlign: 'center',
            alignItems: 'center',
          }}>
          Take Action to Determine Your Fate
            <br />
          Get Predictive Targeting Today!
		    </Typography>
      </div>
    </ThemeProvider>
	)
}
export default TakeActionContainer
