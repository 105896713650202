import { ThemeProvider, createTheme } from '@mui/material/styles';

import Button from '@mui/material/Button';
// import ExpandLessIcon from '@mui/icons-material/ExpandLess';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React from 'react';
import { MemoryRouter as Router } from 'react-router';
// import { makeStyles } from '@mui/styles';
import { withStyles } from '@mui/styles';

const theme = createTheme({
  palette: {
    primary: {
      light: '#5472d3',
      main: '#0d47a1',
      dark: '#002171',
      contrastText: '#ffffff'
    },
    secondary: {
      light: '#D3B554',
      main: '#A1660D',
      dark: '#714F00',
      contrastText: '#000000'
    },
  }
});

const StyledMenu = withStyles({
  paper: {
    border: '0.125em solid #d3d4d5',
    width: '20em',
  },
})((props) => (
  <Menu
    elevation={0}
    getcontentanchorel={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundcolor: 'white',
      '& .MuiListItemIcon-root, & .MuiListItemText-secondary': {
        color: 'white',
        fontColor: 'black',
      },
    },
  },
}))(MenuItem);

export default function Menu1() {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Router>
    <ThemeProvider theme={theme}>
      <div>
          <Button
            className='custom-menu'
            aria-controls='customized-menu'
            aria-haspopup='true'
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={handleClick}
          >
            <MenuIcon />
      </Button>
      <StyledMenu
        id='customized-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <StyledMenuItem onClick={(e) => {
          e.preventDefault();
          window.location.href = '/';
        }}>
          <ListItemText  primary='Home' />
          </StyledMenuItem>
        <StyledMenuItem onClick={(e) => {
          e.preventDefault();
          window.location.href = '/about';
        }}>
          <ListItemText primary='Predictive Process' />
          </StyledMenuItem>
        {/* <StyledMenuItem onClick={(e) => {
          // e.preventDefault();
          // window.location.href = '/methodology';
        // }}>
          {/* <ListItemText primary='Process' /> */}
            {/* </StyledMenuItem> */}






        <StyledMenuItem onClick={(e) => {
          e.preventDefault();
          window.location.href = '/targeting';
        }}>
          <ListItemText primary='Predictive Targeting' />
            </StyledMenuItem>
        <StyledMenuItem onClick={(e) => {
          e.preventDefault();
          window.location.href = '/datarequest';
        }}>
          <ListItemText  primary='Data Request Form' />
          </StyledMenuItem>
        <StyledMenuItem onClick={(e) => {
          e.preventDefault();
          window.location.href = '/casestudies';
        }}>
          <ListItemText primary='Case Study' />
          </StyledMenuItem>
        <StyledMenuItem onClick={(e) => {
          e.preventDefault();
          window.location.href = '/pricing';
        }}>
          <ListItemText primary='Pricing & Customization' />
          </StyledMenuItem>
        <StyledMenuItem onClick={(e) => {
          e.preventDefault();
          window.location.href = '/contact';
        }}>
           <ListItemText primary='Contact Us' />
             </StyledMenuItem>
        {/*     <StyledMenuItem onClick={(e) => {
             <StyledMenuItem onClick={(e) => {
              e.preventDefault();
              window.location.href = '/performancetargeting';
            }}>
              <ListItemText primary='Predictive Targeting' />
            </StyledMenuItem>  */}
          </StyledMenu>
        </div>
      </ThemeProvider>
    </Router>
  );
}
