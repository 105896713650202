import PricingGridContainer from './PricingGridContainer';
import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import background from '../../../../Assets/img/northern_lights.jpg';
import { createTheme } from '@mui/material/styles';
import { responsiveFontSizes } from '@mui/material/styles';

let theme = createTheme();
theme = responsiveFontSizes(theme);

function PricingBackground() {
  return (
    <ThemeProvider theme={theme}>
      <div style={{
          backgroundImage: `url(${background})`,
          backgroundPosition: 'center center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          minHeight: '100em 100vh',
          minWidth: '100% 25em',
          margin: 'auto',
          padding: 'auto',
        }}
      >
      <PricingGridContainer />
      </div>
    </ThemeProvider>
  )
}

export default PricingBackground;
