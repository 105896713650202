import AppBar from '@mui/material/AppBar';
import AppBarButtons from './AppBarButtons/AppBarButtons';
import Logo from './Logo';
import Menu1 from './Menu1';
import MyTheme from '../../../themes/MyTheme';
import React from 'react';
import SearchBar from './SearchBar';
import { ThemeProvider } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import { createTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { responsiveFontSizes } from '@mui/material/styles';

let theme = createTheme(MyTheme);
theme = responsiveFontSizes(theme);

const useStyles = makeStyles((MyTheme) => ({
  appBar: { backgroundColor: theme.palette.primary.main },
  root: {
    flexGrow: 1,
    padding: 0,
    margin: 0,
  },
  menuButton: {
    marginRight: theme.spacing(1),
  },
  appBarButtons: {
    paddingLeft: theme.spacing(4),
  },
  toolbar: {
    minimumHeight: 60,
    display: 'flex',
    direction: 'row',
    justifyContent:'space-between',
    alignItems: 'center',
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    color: '#FFFFFF',
  },
  title: {
    flexGrow: 1,
    alignItems: 'center',
    padding: 0,
    paddingleft: '0',
  },
}));

export default function Header2m() {
  const classes = useStyles();

  return (
    <ThemeProvider theme={MyTheme}>
      <div className={classes.root}>
        <AppBar className={classes.appBar}
          position='sticky'>
          <Toolbar className={classes.toolbar}>
            <Logo />
            <SearchBar />
            <AppBarButtons />
            <Menu1 />
          </Toolbar>
        </AppBar>
      </div>
    </ThemeProvider>
  );
}
