import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import { responsiveFontSizes } from '@mui/material/styles';

let theme = createTheme();
theme = responsiveFontSizes(theme);

function TitleB() {
  return (
    <ThemeProvider theme={theme}>
      <div className="text-container-title-b">
        <h3
          style={{
            color: "ghostwhite",
            justify: "center",
            margin: 'auto',
            padding: '0.25em',
            fontFamily: "Roboto, sans-serif",
            textShadow: "2px 2px 1px black",
          }}>
          Predictive Targeting
        </h3>
      </div>
    </ThemeProvider>
  )
}

export default TitleB;
