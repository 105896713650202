import { createTheme } from '@mui/material/styles';

const MyTheme = createTheme({
palette: {
    primary: {
      light: '#5472d3',
      main: '#0d47a1',
      dark: '#002171',
      contrastText: '#ffffff'
    },
    secondary: {
      light: '#ffbd45',
      main: '#A1660D',
      dark: '#714F00',
      contrastText: '#000000'
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

export default MyTheme;
