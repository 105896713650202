import '../styles/CaseStudies.css';
import '../components/Footer/Footer.css';

import React, { Component, Fragment } from 'react';

import CaseStudyGrid from '../components/Container/CaseStudy/CaseStudyGrid';
import MyTheme from '../themes/MyTheme';
import { ThemeProvider } from '@mui/material/styles';

export default class CaseStudies extends Component {
  render() {
    return (
      <ThemeProvider theme={MyTheme}>
        <Fragment>
          <div className='case-studies-container'>
            <CaseStudyGrid />
          </div>
        </Fragment>
      </ThemeProvider>
    );
  }
}
