import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import { responsiveFontSizes } from '@mui/material/styles';

import React from 'react';

let theme = createTheme();
theme = responsiveFontSizes(theme);


function ImageContainer4() {
  return (
    <ThemeProvider theme={theme}>
      <div
        className='image-container-4'
          display='flexblock'
          direction='row'
          padding='auto'
          margin='auto'
          width='100%'
        >
  	  	<span className='image-container-cs'>
		  		<img
		  			src='https://storage.googleapis.com/appfluencer_com/2018/06/table_ws_case_study.png'
		  			alt='efficacy'
		  			width='100%'>
  	  	  </img>
  	  	</span>
      </div>
    </ThemeProvider>
	)
}

export default ImageContainer4
