import GetCaseStudy from '../../Buttons/GetCaseStudy1';
import MyTheme from '../../../themes/MyTheme';
import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { createTheme } from '@mui/material/styles';
import { responsiveFontSizes } from '@mui/material/styles';

let theme = createTheme();
theme = responsiveFontSizes(theme);

function CaseStudyContainer() {
  return (
    <ThemeProvider theme={MyTheme}>
      <ThemeProvider theme={theme}>
        <div type='container'
          align='center'
          margin='auto'
          padding='1.5em'
          className='get-started'
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            color: 'white',
            backgroundColor: '#FFFFFF',
            size: 'large',
          }}>
          <br />
          <GetCaseStudy />
        </div>
        <div type='container'
          className='learn-about-targeting-container'
          align='center'
          margin='auto'
          paddingtop='1.5em'
          padding='auto'
          sx={{
            textAlign: 'center',
            overflowWrap: 'break-word',
          }}
        >
        <Typography variant='h5' sx={{ padding: '1.5em' }}>
           Read the White Paper
        </Typography>
          {/* <div className='get-started'
            // style={{
//
              // justifyContent: 'center',
              // alignItems: 'center',
              // color: 'white',
              // backgroundColor: '#FFFFFF',
              // size: 'large',
            // }}>
            {/* <br /> */}
            {/* <GetCaseStudy /> */}
          {/* </div> */}
        </div>
      </ThemeProvider>
    </ThemeProvider>
  )
}

export default CaseStudyContainer;
