import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { createTheme } from '@mui/material/styles';
import { responsiveFontSizes } from '@mui/material/styles';

let theme = createTheme();
theme = responsiveFontSizes(theme);

function SubTitleContainer2() {
  return (
    <ThemeProvider theme={theme}>
  		<div className='text-container-cs'>
        <Typography
          variant="h4"
          component="h4"
          align="center"
          style={{
            justify: 'center',
            textAlign: 'center',
            width: '100%',
  		      margin: 'auto',
  		      padding: '1em',
  		      overflowWrap: 'break-word',
  		      textTransform: 'capitalize',
  		    }}>
  		    Appfluencer Predictive Targeting had the lowest percentage of wasted media spend
  		    Increasing campaign Effectiveness
  		  </Typography>
  		</div>
    </ThemeProvider>
	)
}

export default SubTitleContainer2
