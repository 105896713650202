import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { createTheme } from '@mui/material/styles';
import { responsiveFontSizes } from '@mui/material/styles';

let theme = createTheme();
theme = responsiveFontSizes(theme);

function SubTitleContainer1() {
  return (
    <ThemeProvider theme={theme}>
		  <div className='subtitle-container-1'>
		    <span>
		      <Typography
            variant='h4'
            align='center'
		        style={{
		          margin: 'auto',
		          padding: '1em',
		          overflowWrap: 'break-word',
		          width: '100%',
		          textTransform: 'uppercase',
		        }}>
		        Moving beyond Lookalike targeting
		      </Typography>
		    </span>
      </div>
    </ThemeProvider>
	)
}

export default SubTitleContainer1
