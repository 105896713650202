import MyTheme from '../../../themes/MyTheme';
import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import { responsiveFontSizes } from '@mui/material/styles';

let theme = createTheme();
theme = responsiveFontSizes(theme);

function CaseStudySummary2() {
  return (
    <ThemeProvider theme={MyTheme}>
      <ThemeProvider theme={theme}>
		    <div className='text-container-lm'
          style={{
            color: "ghostwhite",
  	    	  textAlign: 'center',
  	    	  fontSize: '1.125rem',
  	    	  fontWeight: '400',
            fontFamily: 'Roboto, sans-serif',
  	    	  margin: 'auto',
            padding: '1.5em',
            width: '80%',
            textShadow: "0.125em 0.125em 0.0625em black",
		    	}}>
		    	Appfluencer's predictive targeting is effective at identifying users who are most likely to install your app, leading to more effective advertising costs and improved revenue generation.
          <br /><br />
          <hr />
        </div>
      </ThemeProvider>
    </ThemeProvider>
	)
}

export default CaseStudySummary2;
