import DataRequestFormGrid from './DataRequest/DataRequestFormGrid';
import MyTheme from '../../../themes/MyTheme';
import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import background from '../../../Assets/img/northern_lights.jpg';

function DataRequestBackground() {
  return (
    <ThemeProvider theme={MyTheme}>
      <div style={{
          backgroundImage: `url(${background})`,
          backgroundPosition: 'center center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          minHeight: '100em 100vh',
          width: 'xs',
          margin: 'auto',
          padding: '2em',
        }}
      >
        <DataRequestFormGrid />
      </div>
    </ThemeProvider>
  )
}

export default DataRequestBackground;
