import MyTheme from '../../../themes/MyTheme';
import React from 'react';
import TargetingInfoButton1 from '../../Buttons/TargetingInfoButton1';
import { ThemeProvider } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { createTheme } from '@mui/material/styles';
import { responsiveFontSizes } from '@mui/material/styles';

let theme = createTheme();
theme = responsiveFontSizes(theme);

function ContactIntro() {
  return (
    <ThemeProvider theme={MyTheme}>
      <ThemeProvider theme={theme}>
        <div type='container'
          className='contact-learnmore-container'
          align='center'
          sx={{
            display: 'flex',
            justifyContent: 'center',
            width: '80%',
            alignItems: 'center',
            typography: 'h4',
            margin: 'auto',
            padding: '1em',
            overflowWrap: 'break-word',
            color: '#FFFFFF',
            backgroundColor: '#FFFFFF',
            size: 'large',
          }}
        >
          <TargetingInfoButton1 />
          <Typography
            variant='h4'
            align='center'
            width='80%'
            style={{
              margin: 'auto',
              padding: '1em',
              overflowWrap: 'break-word',
              fontSize: '1.25em',
            }}>
            Learn how predictive targeting can help you reach your target audience more effectively, without using personal or device information (PII).
          </Typography>
          <br /><br />
        </div>
      </ThemeProvider>
    </ThemeProvider>
  )
}

export default ContactIntro;
