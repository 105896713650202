import MyTheme from '../../../themes/MyTheme';
import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import { responsiveFontSizes } from '@mui/material/styles';

let theme = createTheme();
theme = responsiveFontSizes(theme);

function CaseStudySummary1b() {
  return (
    <ThemeProvider theme={MyTheme}>
      <ThemeProvider theme={theme}>
		    <div className='text-container-cs'
  	    	style={{
  	    	  textAlign: 'center',
  	    	  fontFamily: 'Roboto, sans-serif',
  	    	  fontSize: '1.015em',
            fontWeight: '400',
            color: '#0d47a1',
  	    	  margin: 'auto',
  	    	  padding: 'auto',
  	    	  width: '80%',
		    	}}>
		  		Ten video creatives were provided by the developer. Performance data was not made available. A three day test was completed to gain a rudimentary understanding of creative performance. Creatives were then selected on the basis of whether or not an install had occurred using the creative.
        </div>
      </ThemeProvider>
    </ThemeProvider>
	)
}

export default CaseStudySummary1b;
