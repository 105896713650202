import './App.css';

import React, { Fragment } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import About from './pages/About';
// import Blog from './pages/Blog';
import CaseStudies from './pages/CaseStudies';
import Contact from './pages/Contact';
import DataRequest from './pages/DataRequest';
import Download from './pages/Download';
import Footer from './components/Footer/Footer';
import FreeTrial from './pages/FreeTrial';
import Header2m from './components/Header/CurrentMenu/Header2m';
import Home from './pages/Home';
import MyTheme from './themes/MyTheme';
import NotFound from './pages/NotFound';
import PerformanceTargeting from './pages/PerformanceTargeting';
import Pricing from './pages/Pricing';
import Privacy from './pages/Privacy';
import SubscriberContact from './pages/ThankYou';
import Targeting from './pages/Targeting';
import Terms from './pages/Terms';
import { ThemeProvider } from '@mui/material/styles';
import WhitePaper from './pages/WhitePaper';

// eslint-disable-next-line
export default function (App) {
  return (
    <ThemeProvider theme={MyTheme}>
      <div className='app'>
        <Header2m />
        <Fragment>
          <Router>
            <Routes>
              <Route exact path='/' element={<Home />} />
              <Route exact path='/targeting' element={<Targeting />} />
              <Route exact path='/about' element={<About />} />
              <Route exact path='/casestudies' element={<CaseStudies />} />
              <Route exact path='/privacy' element={<Privacy />} />
              <Route exact path='/terms' element={<Terms />} />
              <Route exact path='/download/' element={<Download />} />
              <Route exact path='/whitepaper/' element={<WhitePaper />} />
              <Route exact path='/contact' element={<Contact />} />
              <Route exact path='/freetrial' element={<FreeTrial />} />
              {/* <Route exact path='/blog' element={<Blog />} /> */}
              <Route exact path='/datarequest' element={<DataRequest />} />
              <Route exact path='/pricing' element={<Pricing />} />
              <Route exact path='/performancetargeting' element={<PerformanceTargeting />} />
              <Route exact path='/thankyou' element={<SubscriberContact  /> } />
              <Route path='*' element={<NotFound />} />
            </Routes>
          </Router>
          </Fragment>
        <Footer />
      </div>
    </ThemeProvider>
  );
}
