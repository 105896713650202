import 'react-toastify/dist/ReactToastify.css';

import React from 'react';
import SubscriptionDataRequestForm from './SubscriptionDataRequest/SubscriptionDataRequestForm';
import SubscriptionDataRequestFormIntro from './SubscriptionDataRequest/SubscriptionDataRequestFormIntro';
import SubscriptionDataRequestTitle from './SubscriptionDataRequest/SubscriptionDataRequestTitle';

export default function SubscriptionDataRequestFormContainer(){
  return (
    <div type='container'
      className='free-trial-container'
      align='center'
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        width: '100%',
        margin: 'auto',
        padding: '1em',
      }}
    >
      <SubscriptionDataRequestTitle />
      <SubscriptionDataRequestFormIntro />
      <SubscriptionDataRequestForm />
    </div>
    );
  }
