import MyTheme from '../../../themes/MyTheme';
import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import { responsiveFontSizes } from '@mui/material/styles';

let theme = createTheme();
theme = responsiveFontSizes(theme);

function CaseStudySummary1e() {
  return (
    <ThemeProvider theme={MyTheme}>
      <ThemeProvider theme={theme}>
		    <div className='text-container-cs'
  	    	style={{
  	    	  textAlign: 'left',
  	    	  fontFamily: 'Roboto, sans-serif',
  	    	  fontSize: '1.015em',
            fontWeight: '400',
            color: '#0d47a1',
  	    	  margin: 'auto',
  	    	  padding: '0em',
  	    	  width: '50%',
		    	}}>
          <ul>
            <li>
              Identify your objectives and target audience
            </li>
            <li>
              Predictive Target List prepared
            </li>
            <li>
              Run mobile campaigns
            </li>
            <li>
              Validate Targeting results, modify list if required
            </li>
          </ul>
        </div>
      </ThemeProvider>
    </ThemeProvider>
	)
}

export default CaseStudySummary1e;
