import 'react-toastify/dist/ReactToastify.css';

import DataRequestForm from './DataRequest/DataRequestForm';
import DataRequestFormIntro from './DataRequest/DataRequestFormIntro';
import DataRequestTitle from './DataRequest/DataRequestTitle';
// import MyTheme from '../themes/myTheme';
import React from 'react';

// import { ThemeProvider } from '@mui/material/styles';

export default function DataRequestFormContainer(){
  return (
    <div type='container'
      className='free-trial-container'
      align='center'
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        width: '100%',
        margin: 'auto',
        padding: '1em',
      }}
    >
      <DataRequestTitle />
      <DataRequestFormIntro />
      <DataRequestForm />
    </div>
    );
  }
