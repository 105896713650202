import * as React from 'react';

import { ThemeProvider, createTheme } from '@mui/material/styles';

import { Container } from '@mui/material';
import { Typography } from '@mui/material';
import { responsiveFontSizes } from '@mui/material/styles';

let theme = createTheme();
theme = responsiveFontSizes(theme);

function TitleContainer1() {
  return (
    <ThemeProvider theme={theme}>
      <Container>
        <div className='case-studies-title'>
          <Typography
            variant='h1'
            align='center'
            style={{
              justify: 'center',
              color: "ghostwhite",
              fontFamily: 'Roboto, sans-serif',
              fontWeight: '900',
              textTransform: 'capitalize',
              margin: 'auto',
              paddingTop: '1.350em',
              padding: 'auto',
              textShadow: "2px 2px 1px black",
            }}>
            Pricing & Customization
			    </Typography>
        </div>
      </Container>
    </ThemeProvider>
	)
}

export default TitleContainer1
