import '../components/Footer/Footer.css';

import React, { Component, Fragment } from 'react';

import MyTheme from '../themes/MyTheme';
import { ThemeProvider } from '@mui/material/styles';

export default class Download extends Component {
	render() {
    return (
      <ThemeProvider theme={MyTheme}>
			<Fragment>
				<div className='wordscapes-container'
					maxwidth='false'
					style={{
            justifyContent: 'center',
            minHeight: '100vh',
            padding: 0,
            margin: 0
          }}>
					<div className='ws-download'
						maxwidth='false'
          	style={{
          	  display: 'flex',
          	  flexDirection: 'row',
          	  padding: 0,
          	  margin: 0,
						}}>
						<iframe src="https://shared2you.wufoo.com/forms/x84gtta1phu93a/" title='Case Study ⏤ Overcoming Facebook Limitations' style={{ frameborder: 0, border: 0, width:1366, height:983, paddingBottom: 0, marginBottom: 0}} allowFullScreen={true} />
					</div>
				</div>
        </Fragment>
      </ThemeProvider>
		)
	}
}
