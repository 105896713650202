import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { createTheme } from '@mui/material/styles';
import { responsiveFontSizes } from '@mui/material/styles';

let theme = createTheme();
theme = responsiveFontSizes(theme);

function DataRequestFormIntro() {
  return (
    <ThemeProvider theme={theme}>
      <div className='data-request-form-container'>
          <Typography
            variant='h4'
            align='center'
            style={{
              color: 'ghostwhite',
              fontFamily: 'Roboto',
              margin: 'auto',
              padding: 'auto',
              paddingBottom: '1.5em',
              overflowWrap: 'break-word',
              fontSize: '1.375rem',
              width: '80%',
          }}>
          and it's using predictive technology.

          When the phone rings, don't let it go to voicemail!

          Use predictive targeting to create your future.
          </Typography>
      </div>
    </ThemeProvider>
  )
}

export default DataRequestFormIntro;
