import { Theme, createStyles, makeStyles } from '@mui/styles';

import { Container } from '@mui/material';
import Grid from '@mui/material/Grid';
import PredictiveCardContainer3 from './Cards/PredictiveCardContainer3';
// import PricingSubTitleContainer from './PricingSubTitleContainer';
import PricingSubTitleContainer1 from './PricingSubTitleContainer1';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
  }),
);

export default function PricingCardContainer() {
	const classes = useStyles();

	return (
		<Container
			width='80%'
		>
			<div className={classes.root}>
				<Grid container spacing={3}
  				direction="row"
  				justifyContent="center"
  				alignItems="center"
				>
					<Grid item xs={12}>
					  <PredictiveCardContainer3 />
					</Grid>
					<Grid item xs={12}>
						<PricingSubTitleContainer1 />
					</Grid>
					<Grid item xs={12}>
						<div className='spaceman' sx={{padding: '2em'}}>

						</div>
					</Grid>
				</Grid>
			</div>
		</Container>
	)
}
