import { Theme, createStyles, makeStyles } from '@mui/styles';

import CallToActionContainer from '../CaseStudy/CallToActionContainer';
import CaseStudyContainer from '../CaseStudy/CaseStudyContainer';
import CustomerStatement1 from '../CaseStudy/CustomerStatement1';
import Grid from '@mui/material/Grid';
import ImageContainer1 from '../CaseStudy/ImageContainer1';
import ImageContainer2 from '../CaseStudy/ImageContainer2';
import ImageContainer4 from '../CaseStudy/ImageContainer4';
import MoreInfoContainer1 from './MoreInfoContainer1';
import React from 'react';
import SubTitleContainer1 from '../CaseStudy/SubTitleContainer1';
import SubTitleContainer2 from '../CaseStudy/SubTitleContainer2';
import SubTitleContainer3 from './SubTitleContainer3';
import TitleContainer from '../CaseStudy/TitleContainer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
  }),
);

export default function CaseStudyGrid() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={2}
      >
         <Grid item xs={12}></Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <TitleContainer />
        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={2}>
          </Grid>
        <Grid item xs={10} sm={10} md={10} lg={8}>
          <SubTitleContainer1 />
        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={2}>
          </Grid>
        <Grid item xs={1} sm={1} md={1} lg={2}>
          </Grid>
        <Grid item xs={10} sm={10} md={10} lg={8}>
          <ImageContainer1 />
        </Grid>
          <Grid item xs={1} sm={1} md={1} lg={2}>
        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={2}>
          </Grid>
        <Grid item xs={10} sm={10} md={10} lg={8}>
          <SubTitleContainer2 />
        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={2}>
          </Grid>
        <Grid item xs={1} sm={1} md={1} lg={2}>
          </Grid>
       <Grid item xs={10} sm={10} md={10} lg={8}>
          <ImageContainer2 />
        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={2}>
          </Grid>

        <Grid item xs={1} sm={1} md={1} lg={2}>
          </Grid>
        <Grid item xs={10} sm={10} md={10} lg={8}>
          <SubTitleContainer3 />
        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={2}>
          </Grid>

        <Grid item xs={1} sm={1} md={1} lg={2}>
          </Grid>
        <Grid item xs={10} sm={10} md={10} lg={8}>
          <ImageContainer4 />
        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={2}>
          </Grid>
        <Grid item xs={1} sm={1} md={1} lg={2}>
          </Grid>
        <Grid item xs={10} sm={10} md={10} lg={8}>
          <CustomerStatement1 />
        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={2}>
        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={2}>
          </Grid>
        <Grid item xs={10} sm={10} md={10} lg={8}>
          <CallToActionContainer />
        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={2}>
          </Grid>
        <Grid item xs={1} sm={1} md={1} lg={2}>
          </Grid>
        <Grid item xs={10} sm={10} md={10} lg={8}>
          <MoreInfoContainer1 />
        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={2}>
          </Grid>
        <Grid item xs={1} sm={1} md={1} lg={2}>
          </Grid>
        <Grid item xs={10} sm={10} md={10} lg={8}>
          <CaseStudyContainer />
        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={2}>
          </Grid>
        <Grid item xs={12}>
        </Grid>
      </Grid>
    </div>
  );
}
