import Hidden from '@mui/material/Hidden';
import { Link } from 'react-router-dom';
import MyTheme from '../themes/MyTheme';
import NotFound from '../Assets/img/NotFound.png';
import React from 'react';
import { ThemeProvider } from '@mui/material/styles';

function PageNotFound() {
  return (
    <ThemeProvider theme={MyTheme}>
    <div className='page-not-found'
      style={{
        background: "#714F00",
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: '1.25em',
        margin: 'auto',
        fontFamily: 'Roboto',
        color: '#000000',
      }}>
      <h3 style={{
        fontFamily: 'Roboto',
        color: '#000000',
        padding: 'auto',
        margin: 'auto',
      }}
      >You're Not in Kansas Anymore...</h3>
      <h5 style={{
        textAlign: 'center',
        fontFamily: 'Roboto',
        color: '#000000',
        fontSize: '0.875rem',
        fontWeight: '500',
      }}>
        Neither are We...</h5>
      <Hidden only={'xs'}>
      <img alt=''
        style={{
          width: 'auto',
        }}
          src={NotFound} /></Hidden>
      <div style={{
        padding: 'auto',
        margin: 'auto',
        fontSize: '1.5rem',
        fontWeight: 'normal',
      }}
        >
        <div style={{ padding: '1.875rem', margin: '1.875rem' }}>
          <Link to='/'>There's No Place Like Home</Link>
        </div>
      </div>
      </div>
    </ThemeProvider>
  )
}

export default PageNotFound
