import 'react-toastify/dist/ReactToastify.css';

import FreeTrialRequestForm from './FreeTrialRequest/FreeTrialRequestForm';
import FreeTrialRequestFormIntro from './FreeTrialRequest/FreeTrialRequestFormIntro';
import FreeTrialRequestFormTitle from './FreeTrialRequest/FreeTrialRequestFormTitle';
import React from 'react';

export default function FreeTrialRequestFormContainer(){
  return (
    <div type='container'
      className='free-trial-container'
      align='center'
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        width: '100%',
        margin: 'auto',
        padding: '1em',
      }}
    >
      <FreeTrialRequestFormTitle />
      <br />
      <FreeTrialRequestFormIntro />
      <FreeTrialRequestForm />
    </div>

    );
  }
