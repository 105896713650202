// import './Pricing.css';

import * as React from 'react';

// import Card from '@mui/material/Card';
// import CardContent from '@mui/material/CardContent';
// import Container from '@mui/material/Container';
// import Hidden from '@mui/material/Hidden';
import PricingBackground from '../components/Container/Pricing/Container/PricingBackground';

// import { ThemeProvider, createTheme } from '@mui/material/styles';


// const theme = createTheme({
  // palette: {
    // primary: {
      // light: '#5472d3',
      // main: '#0d47a1',
      // dark: '#002171',
      // contrastText: '#ffffff',
    // },
    // secondary: {
      // light: '#D3B554',
      // main: '#A1660D',
      // dark: '#714F00',
      // contrastText: '#000000',
    // },
  // }
// });

function PricingContent() {
  return (
    <div>
      <PricingBackground />
    </div>
  );
}

export default function Pricing() {
  return <PricingContent />;
}
