import Button from '@mui/material/Button';
import MyTheme from '../../themes/MyTheme';
import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((MyTheme) => ({
  root: {
    '& > *': {
      margin: MyTheme.spacing(1),
      padding: MyTheme.spacing(2),
      width: '18ch',
      height: '6ch',
      border: '0.250em solid #FFFFFF',
      borderRadius: '0.625em',
      color: 'ghostwhite',
      '&:hover': {
        backgroundColor: '#FFF',
        color: '#fff',
        border: '0.250em solid black',
      },
    },
  },
}));

export default function TargetingInfoButton1() {
  const classes = useStyles();

  return (
    <ThemeProvider theme={MyTheme}>
      <div className={classes.root}>
        <Button
          variant='contained'
          href='#gs-targeting-info-button'
          onClick={(e) => {
          e.preventDefault();
          window.location.href = '/targeting';
          }}>
          <Typography variant='button'>
            White Paper
          </Typography>
        </Button>
      </div>
    </ThemeProvider>
  );
}
