import ContactFormGrid from './ContactFormGrid';
// import MyTheme from '../../../themes/MyTheme';
import React from 'react';
// import { ThemeProvider } from '@mui/material/styles';
import background from '../../../Assets/img/northern_lights.jpg';

function ContactBackground() {
  return (
    <div
      style={{
        backgroundImage: `url(${background})`,
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        minHeight: '100em 100vh',
        minWidth: '100% 25em',
        margin: 'auto',
        padding: 'auto',
      }}
    >
    <ContactFormGrid />
    </div>
  )
}

export default ContactBackground;
