import '../styles/Targeting.css';
import '../components/Footer/Footer.css';

import React, { Component, Fragment } from 'react';

import { Grid } from '@mui/material';
import MyTheme from '../themes/MyTheme';
import TargetingGrid from '../components/Container/Targeting/TargetingGrid';
import { ThemeProvider } from '@mui/material/styles';

export default class Targeting extends Component {
  render() {
    return (
      <ThemeProvider theme={MyTheme}>
        <Fragment>
          <Grid>
            <div className='new-study'>
              <TargetingGrid />
            </div>
          </Grid>
        </Fragment>
      </ThemeProvider>
    );
  }
}
