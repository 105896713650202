import "react-toastify/dist/ReactToastify.css";
import "./ContactUs.css";

import React from "react";
import emailjs from '@emailjs/browser';
// import { Bounce, ToastContainer, Zoom, toast } from "react-toastify";
import { toast } from "react-toastify";

export default function ContactUs() {
  const notify = () =>
    toast.info("sent 2", {
      className: "sent",
      draggable: true,
      position: toast.POSITION.TOP_CENTER
    });

  toast.error("error !");
  toast.success("success!");
  // toast.info("info!")
  // toast.warn("warn!")

  function sendEmail(e) {
    e.preventDefault();

    const cfg = {
      service_id: "service_26c4nst",
      template_id: "template_za3dzsq",
      user_ID: "E5F2l4Sr7M2va11xS"
    };

    const params = {
      name: e.target.user_name.value,
      company: e.target.company.value,
      email: e.target.user_email.value,
      phone: e.target.user_phone.value,
      message: e.target.message.value
    };
    console.log(params.name);
    console.log(params.company);
    console.log(params.email);
    console.log(params.phone);
    console.log(params.message);

    emailjs.send(cfg.service_id, cfg.template_id, params, cfg.user_ID).then(
      (result) => {
        notify();
      },
      (error) => {
      }
    );
    e.target.reset();
  }

  return (
    <form name="tn" id="tn" className="contact-form" onSubmit={sendEmail}>
      <label>Name</label>
      <input type="text" name="user_name" id="user_name" />

      <label>Company</label>
      <input type="text" name="company" id="company" />

      <label>Email</label>
      <input type="email" name="user_email" id="user_email" />

      <label>Phone</label>
      <input type="text" name="user_phone" id="user_phone" />

      <label>Message</label>
      <textarea name="message" />

      <input type="submit" value="Send" />
    </form>
  );
};
