import MyTheme from '../../../themes/MyTheme';
import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import { responsiveFontSizes } from '@mui/material/styles';

let theme = createTheme();
theme = responsiveFontSizes(theme);

function CaseStudySummary5() {
  return (
    <ThemeProvider theme={MyTheme}>
      <ThemeProvider theme={theme}>
		    <div className='text-container-cs'
  	    	style={{
  	    	  textAlign: 'center',
  	    	  fontFamily: 'Roboto, sans-serif',
  	    	  fontSize: '1.015em',
            fontWeight: '400',
            color: '#0d47a1',
  	    	  margin: 'auto',
  	    	  padding: '1em',
  	    	  width: '80%',
		    	}}>
			    Mobile campaigns using Appfluencer's predictive targeting were successful in outperforming the developer's KPIs in nearly every instance.
        </div>
      </ThemeProvider>
    </ThemeProvider>
	)
}

export default CaseStudySummary5;
