import React, { Component, Fragment } from 'react';
// import '../styles/Targeting.css';

export default class PerformanceTargeting extends Component {
  render() {
    return (
      <Fragment>
        <div className="container-performance-targeting" maxwidth="false"
          style={{
            justifyContent: 'center',
            minHeight: '100vh',
            backgroundColor: '#0d47a1',
            padding: 0,
            margin: 0
          }}>
          <div className="container-performance-targeting" maxwidth="false"
          style={{
            display: 'flex',
            flexDirection: 'row',
            padding: 0,
            margin: 0,
          }}>
          <iframe title="predictiveTargeting "src="https://datastudio.google.com/embed/reporting/d2e37cf5-d3c6-4803-880d-74c540c9e17c/page/nAIjC" style={{ frameborder:0, border:0, width: 1366, height: 983,  paddingBottom: 0, marginBottom: 0}} allowFullScreen={true} />
        </div>
      </div>
    </Fragment>
  );
}}
