import MyTheme from '../../../themes/MyTheme';
import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { createTheme } from '@mui/material/styles';
import { responsiveFontSizes } from '@mui/material/styles';

let theme = createTheme();
theme = responsiveFontSizes(theme);

function TitleContainer1() {
  return (
    <ThemeProvider theme={MyTheme}>
      <ThemeProvider theme={theme}>
        <div className='we-target-title'>
          <Typography
            variant='h1'
            align='center'
            style={{
              justify: 'center',
              margin: 'auto',
              paddingTop: '1em',
              padding: 'auto',
              fontFamily: 'Roboto, sans-serif',
              textTransform: 'capitalize',
              fontWeight: '900',
              textShadow: "2px 2px 1px black",
            }}>
            Meet Growth Objectives using Predictive Targeting
          </Typography>
        </div>
      </ThemeProvider>
    </ThemeProvider>
  )
}

export default TitleContainer1;
