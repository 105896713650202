import { Theme, createStyles, makeStyles } from '@mui/styles';

// import CardContainer from './PricingCardContainer';
import Grid from '@mui/material/Grid';
import PricingPage from '../Stripe/Pricing/PricingPage';
import React from 'react';
// import SubTitleContainer from '../../Targeting/PricingSubTitleContainer';
// import TitleContainer from '../../Targeting/PricingTitleContainer';
import TitleContainer1 from './TitleContainer1';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
  }),
);

export default function PricingGridContainer() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} sx={{ height: 'auto', margin: 'auto'}}>
          <TitleContainer1 />
        </Grid>
        <Grid item xs={12}>
          <PricingPage />
        </Grid>
        <Grid item xs={12}>

        </Grid>
        <Grid item xs={12}>

        </Grid>
        <Grid item xs={12}>

        </Grid>
      </Grid>
    </div>
  );
}
