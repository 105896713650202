import MyTheme from '../../../themes/MyTheme';
import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { createTheme } from '@mui/material/styles';
import { responsiveFontSizes } from '@mui/material/styles';

let theme = createTheme();
theme = responsiveFontSizes(theme);

function CaseStudyIntro3() {
  return (
    <ThemeProvider theme={MyTheme}>
      <ThemeProvider theme={theme}>
        <div>
          <span className='intro3-container'>
            <Typography
              variant='h3'
              align='center'
              style={{
                color: "ghostwhite",
                margin: 'auto',
                padding: 'auto',
                paddingBottom: '1.5em',
                overflowWrap: 'break-word',
                fontSize: '1.25rem',
                width: '80%',
                fontFamily: "Roboto, sans-serif",
                textShadow: "0.125em 0.125em 0.0625em black",
              }}>
              Effective Targeting identifies new users, improves ROI, and delivers more value from mobile ad campaigns.
              <br /><br />
              Contact us for more information.
            </Typography>
          </span>
        </div>
      </ThemeProvider>
    </ThemeProvider>
  )
}

export default CaseStudyIntro3;
