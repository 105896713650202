import * as React from 'react';

function PricingPage() {
  // Paste the stripe-pricing-table snippet in your React component
  return (

    <stripe-pricing-table pricing-table-id="prctbl_1NAgfPF0MWCTcFaPpXGk51a9"
      publishable-key="pk_live_ROsoHO9kqogBax1BrPwpTRgL"
      client-reference-id="<%= current_user.id %>"
      frame-src="https://js.stripe.com"
      script-src="https://js.stripe.com"
    >
    </stripe-pricing-table>
  );
}

export default PricingPage;
