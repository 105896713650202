import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import { responsiveFontSizes } from '@mui/material/styles';

let theme = createTheme();
theme = responsiveFontSizes(theme);

function TitleC() {
  return (
    <ThemeProvider theme={theme}>
      <div className="text-container-title-c">
        <h5
          style={{
            textAlign: "center",
            fontSize: '1.625rem',
            fontFamily: 'Roboto, sans-serif',
            padding: '0.625em',
            margin: 'auto',
            color: 'ghostwhite',
            textShadow: "2px 2px 1px black",
          }}
        >
          Use Predictive Targeting To
          <br></br>
          Improve Campaign Effectiveness
          <br></br>
          Increases Performance
          <br></br>
          Reduce Optimization Time
          <br></br>
          All Without Using Personal Data
        </h5>
      </div>
    </ThemeProvider>
  )
}

export default TitleC;
