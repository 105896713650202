import React from 'react'
import { ThemeProvider } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { responsiveFontSizes } from '@mui/material/styles';

let theme = createTheme();
theme = responsiveFontSizes(theme);

function SubscriptionDataRequestTitle() {
  return (
    <ThemeProvider theme={theme}>
      <div className="data-request-container-title">
        <Typography
          variant='h3'
          style={{
            color: "ghostwhite",
            justify: "center",
            margin: 'auto',
            fontFamily: "Roboto, sans-serif",
            FontSize: '1.25em',
            textShadow: "2px 2px 1px black",
            paddingTop: 'auto',
            padding: 'auto',
            textTransform: 'capitalize',
            fontWeight: '900',
          }}>
          Thank You for Subscribing!
        </Typography>
      </div>
    </ThemeProvider>
  )
}

export default SubscriptionDataRequestTitle;
