import FreeTrialRequestFormBackground from '../components/Container/FreeTrialRequestForm/FreeTrialRequestFormBackground';
import React from 'react';

function FreeTrial() {
  return (
    <div>
      <FreeTrialRequestFormBackground />
    </div>
  )
}

export default FreeTrial;
