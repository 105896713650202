import GetCaseStudy from '../../Buttons/GetCaseStudy';
import MyTheme from '../../../themes/MyTheme';
import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { createTheme } from '@mui/material/styles';
import { responsiveFontSizes } from '@mui/material/styles';

let theme = createTheme();
theme = responsiveFontSizes(theme);

function CaseStudyContainer() {
  return (
		<ThemeProvider theme={MyTheme}>
			<ThemeProvider theme={theme}>
	  	<div className='case-study-container'
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
	        background: '#0d47a1',
	        color: 'ghostwhite',
	        margin: 'auto',
          padding: 'auto',
          paddingBottom: '3em',
	        width: '100%',
	        overflowWrap: 'break-word',
					borderRadius: '0.625em',
        }}>
        <Typography
          variant='h5'
          align='center'
	        style={{
            justifyContent: 'center',
            textAlign: 'center',
            color: 'ghostwhite',
	          fontWeight: '200',
	          fontSize: '1.125em',
            paddingBottom: '2em',
            paddingtop: '2em',
	          margin: 'auto',
            padding: '1em',
	          fontStyle: 'normal',
					  overflowWrap: 'break-word'
	        }}>
	      User Privacy is at the core of Appfluencer<sup>®</sup> Predictive Targeting.
	      <hr />
				Appfluencer's Predictive Targeting process generates custom target lists to help businesses reliant on mobile apps to reach new customers.
				<br /><br />
				The Wordscapes case study illustrates the use of Appfluencer's Predictive Targeting on other ad platforms. Businesses can leverage their knowledge of different ad platforms and Predictive Targeting.
				<hr />
				One of the key factors driving performance on Facebook's ad platform, now Meta, was the use of &nbsp;<strong> "Appfluencer's Predictive targeting. Campaign performance increased through highly effective targeting."</strong>
			</Typography>
        <span>
          <Typography
            variant='h5'
            align='center'
	          style={{
	            color: 'ghostwhite',
	            fontWeight: '200',
	            fontSize: '1.125em',
	            textAlign: 'center',
	            margin: 'auto',
	            padding: 'auto',
	          }}>
							Gabe Kwakyi, Co-Founder & Chief Marketing Officer, Incipia
				<hr />
          </Typography>
          </span>
			<div
				style={{
					alignItems: 'center',
          justifyContent: 'center',
          paddingtop: '1.125em',
          padding: 'auto',
					margin: 'auto',
					color: '#FFFFFF',
				}}>
	    	  <GetCaseStudy />
        </div>
      </div>
			</ThemeProvider>
		</ThemeProvider>
	)
}

export default CaseStudyContainer
