import { Theme, createStyles, makeStyles } from '@mui/styles';

import ContactUsContainer from './ContactUsContainer';
import Grid from '@mui/material/Grid';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(0),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
  }),
);

export default function ContactFormGrid() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={2}>
          </Grid>
        <Grid item xs={8}>
          <ContactUsContainer />
        </Grid>
        <Grid item xs={2}>
          </Grid>
        <Grid item xs={12}>
          <div padding="3em">
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
