import Box from '@mui/material/Box';
import React from 'react';
import logo from './../../../Assets/img/logo.png';

function Logo() {
  return (
    <div>
    <Box
      component={'img'}
      sx={{ maxWidth: 210 }}
      alt='appfluencer-logo'
      src={logo}
    />
    </div>
  )
}

export default Logo;
