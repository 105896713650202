import React from 'react'
import { ThemeProvider } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { responsiveFontSizes } from '@mui/material/styles';

let theme = createTheme();
theme = responsiveFontSizes(theme);

function FreeTrialRequestFormTitle() {
  return (
    <ThemeProvider theme={theme}>
      <div className="data-request-container-title">
        <Typography
          variant='h4'
          style={{
            color: "ghostwhite",
            justify: "center",
            margin: 'auto',
            fontFamily: "Roboto, sans-serif",
            textShadow: "2px 2px 1px black",
            padding: 'auto',
            width: '100%',
            textTransform: 'capitalize',
            fontWeight: '900',
          }}>
          Free Trial Request
        </Typography>
      </div>
    </ThemeProvider>
  )
}

export default FreeTrialRequestFormTitle;
