import MyTheme from '../../../themes/MyTheme';
import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { createTheme } from '@mui/material/styles';
import { responsiveFontSizes } from '@mui/material/styles';

let theme = createTheme();
theme = responsiveFontSizes(theme);

function CaseStudyIntro1d() {
  return (
    <ThemeProvider theme={MyTheme}>
      <ThemeProvider theme={theme}>
        <div>
          <span className='intro1-container'>
            <Typography
              variant='h3'
              align='center'
            style={{
              margin: 'auto',
              padding: '1em',
              overflowWrap: 'break-word',
              fontSize: '1.25em',
              fontFamily: 'Roboto, sans-serif',
              fontWeight: '900',
            }}>
              Conclusion
            </Typography>
          </span>
        </div>
      </ThemeProvider>
    </ThemeProvider>
  )
}

export default CaseStudyIntro1d;
