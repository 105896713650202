import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { createTheme } from '@mui/material/styles';
import { responsiveFontSizes } from '@mui/material/styles';

let theme = createTheme();
theme = responsiveFontSizes(theme);

function ContactFormIntro() {
  return (
    <ThemeProvider theme={theme}>
      <div className='contact-form-container'>
        <Typography
          variant='h3'
          align='center'
          style={{
            color: 'ghostwhite',
            fontFamily: 'Roboto',
            margin: 'auto',
            padding: '1.25em',
            overflowWrap: 'break-word',
            fontSize: '1.5rem',
        }}>
          Thanks for stopping by!
          <br /><br />
          Please let us know how we can help.
        </Typography>
      </div>
    </ThemeProvider>
  )
}

export default ContactFormIntro;
