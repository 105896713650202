import MyTheme from '../../../themes/MyTheme';
import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import { responsiveFontSizes } from '@mui/material/styles';

let theme = createTheme();
theme = responsiveFontSizes(theme);

function CaseStudySummary() {
  return (
    <ThemeProvider theme={MyTheme}>
      <ThemeProvider theme={theme}>
		    <div className='text-container-cs'
  	    	style={{
            textAlign: 'center',
            fontFamily: 'Roboto, sans-serif',
  	    	  fontSize: '1.015em',
            fontWeight: '400',
            color: '#0d47a1',
  	    	  margin: 'auto',
  	    	  padding: 'auto',
  	    	  width: '80%',
  	    	}}>
  	    	Appfluencer's predicitive targeting helps company's acquire new users
		    	by effectively reaching their target audience anywhere mobile campaigns are run – on Social, SDK or DSP platforms.
          This white paper highlights the use and effectiveness of Appfluencer's predictive targeting solution.
          <br /><br />
		    	<hr></hr>
        </div>
      </ThemeProvider>
    </ThemeProvider>
	)
}

export default CaseStudySummary;
