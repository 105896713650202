import MyTheme from '../../../themes/MyTheme';
import React from 'react'
import { ThemeProvider } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { responsiveFontSizes } from '@mui/material/styles';

let theme = createTheme();
theme = responsiveFontSizes(theme);

function TitleA() {
  return (
    <ThemeProvider theme = { MyTheme } >
      <ThemeProvider theme={theme}>
        <div className="home-page-container-title">
          <Typography
            variant='h1'
            style={{
              color: "ghostwhite",
              justify: "center",
              margin: 'auto',
              paddingTop: '1.350em',
              padding: 'auto',
              fontFamily: "Roboto, sans-serif",
              textShadow: "2px 2px 1px black",
              textTransform: 'capitalize',
              fontWeight: '900',
            }}>
            Appfluencer
          </Typography>
        </div>
      </ThemeProvider>
    </ThemeProvider>
  )
}

export default TitleA;
