import * as React from 'react';

import { List, ListItem } from '@mui/material'
import { ThemeProvider, createTheme } from '@mui/material/styles';

import { Card } from '@mui/material';
import { CardContent } from '@mui/material';
import { Container } from '@mui/material';
import { Typography } from '@mui/material';
import { responsiveFontSizes } from '@mui/material/styles';

let theme = createTheme();
theme = responsiveFontSizes(theme);


export default function PredictiveCardContainer3() {
  return (
    <ThemeProvider theme={theme}>
      <Container>
        <div className='card-container-pricing'>
          <Card className='predictive-1'
            style={{
              display: 'flex',
              direction: 'column',
              justifyContent: 'center',
              alignContent: 'center',
              background: '#002171',
              color: '#fff',
              variant: 'outlined',
              border: '0.0625em solid',
              width: '100%',
              margin: 'auto',
              height: 'auto'
              }}>

            <CardContent>
              <div className='card-title'>
                <Typography
                  variant='h4'
                  style={{
                    display: 'flex',
                    direction: 'column',
                    justifyContent: 'center',
                    textAlign: 'center',
                    fontFamily: 'Roboto, sans-serif',
                    fontSize: '1.75rem',
                    fontWeight: '400',
                    color: '#ffffff',
                    margin: 'auto',
                    padding: 'auto',
                    overflowWrap: 'break-word',
                    width: '90%',
                  }}>
                  Predictive Targeting Benefits include:
                </Typography>
                </div>
              <Typography
                variant='h5'
                align='left'
                style={{
                  background: '#002171',
                  color: 'ghostwhite',
                  fontWeight: '300',
                  fontSize: '1.0em',
                  fontFamily: 'Roboto, open-sans',
                  margin: 'auto',
                  padding: 'auto',
                  overflowWrap: 'break-word',
                  width: '70%',
                  borderRadius: '0.625em',
                }}>
                <List sx={{ listStyleType: 'disc', pl: 4 }}>
                  <ListItem sx={{ display: 'list-item' }}>
                      <strong>Improved targeting accuracy:</strong> By targeting ads to users who are more likely to be interested in an app, mobile app developers can improve the accuracy of their targeting and increase their conversion rates.
                  </ListItem>
                  <ListItem sx={{ display: 'list-item' }}>
                    <strong>Increased reach:</strong> By targeting ads to a wider audience, mobile app developers can reach more potential users and increase their chances of getting new installs.
                  </ListItem>
                  <ListItem sx={{ display: 'list-item' }}>
                    <strong>Improved ROI:</strong> By targeting ads more effectively, mobile app developers can improve their ROI and get more value out of their advertising campaigns.
                  </ListItem>
                </List>
              </Typography>
            </CardContent>
          </Card>
        </div>
      </Container>
    </ThemeProvider>
  )
}
