import MyTheme from '../../../themes/MyTheme';
import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import { responsiveFontSizes } from '@mui/material/styles';

let theme = createTheme();
theme = responsiveFontSizes(theme);

function CustomerStatement1() {
  return (
    <ThemeProvider theme={MyTheme}>
      <ThemeProvider theme={theme}>
		    <div className='text-container-cs'
  	    	style={{
            textAlign: 'center',
            fontFamily: 'Roboto, sans-serif',
  	    	  fontSize: '1.125rem',
  	    	  fontWeight: '400',
  	    	  margin: 'auto',
  	    	  padding: '1em',
  	    	  width: '100%',
  	    	  color: '#0d47a1',
  	    	}}>
  	    	"Using Appfluencer Predictive Targeting on Facebook was a huge win as it allowed us to overcome the scalability challenges of Lookalike targeting and the dilutive effect that occurs the further away you target from 2% Lookalikes.&nbsp; Appluencer's Predictive Targeting helped us save time and money, driving better performance from our Facebook campaigns"<br /><br /> - John Boog-Scott, COO, Peoplefun
          </div>
      </ThemeProvider>
    </ThemeProvider>
	)
}

export default CustomerStatement1;
