import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import { responsiveFontSizes } from '@mui/material/styles';

import React from 'react';

let theme = createTheme();
theme = responsiveFontSizes(theme);

function ImageContainer2() {
  return (
    <ThemeProvider theme={theme}>
      <div
        className='image-container-2'
        display='flexblock'
        direction='row'
        padding='auto'
        margin='auto'
        width='100%'
      >
  	  	<span>
		  		<img src='https://storage.googleapis.com/appfluencer_com/2018/06/total-spend-vs-wasted-spend.png'
		  			alt='wasted-spend'
		  			width='100%'
		  			border='1'
		  			color='#000000'>
  	  	  </img>
  	  	</span>
      </div>
    </ThemeProvider>
	)
}

export default ImageContainer2
