import MyTheme from '../../../themes/MyTheme';
import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import { responsiveFontSizes } from '@mui/material/styles';

let theme = createTheme();
theme = responsiveFontSizes(theme);

function CaseStudySummary1() {
  return (
    <ThemeProvider theme={MyTheme}>
      <ThemeProvider theme={theme}>
		    <div className='text-container-cs'
  	    	style={{
  	    	  textAlign: 'center',
  	    	  fontFamily: 'Roboto, sans-serif',
            fontSize: '1.015em',
            fontWeight: '400',
            color: '#0d47a1',
  	    	  margin: 'auto',
  	    	  padding: 'auto',
  	    	  width: '80%',
		    	}}>
          Wanting to grow their game, a developer engaged Appfluencer for a 30-day test. The test subject, was a mobile game initially launched in 2012. Despite the occurrence of industry changes since launch, the game was still profitable in an increasing complex marketplace.
          Prior to starting, the developer shared their KPIs and target audience.
        </div>
      </ThemeProvider>
    </ThemeProvider>
	)
}

export default CaseStudySummary1;
