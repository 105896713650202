import '../styles/About.css';
import '../components/Footer/Footer.css';

import React, { Component, Fragment } from 'react';

import AboutGrid from '../components/Container/About/AboutGrid';
import { Grid } from '@mui/material';
import MyTheme from '../themes/MyTheme';
import { ThemeProvider } from '@mui/material/styles';

export default class About extends Component {
  render() {
    return (
      <ThemeProvider theme={MyTheme}>
        <Fragment>
          <Grid>
            <div className='about'>
              <AboutGrid />
            </div>
          </Grid>
        </Fragment>
      </ThemeProvider>
    );
  }
}
