import { Container } from '@mui/material';
import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { createTheme } from '@mui/material/styles';
import { responsiveFontSizes } from '@mui/material/styles';

let theme = createTheme();
theme = responsiveFontSizes(theme);

function PricingSubTitleContainer1() {
  return (
    <ThemeProvider theme={theme}>
      <Container>
        <div className='text-container-cs'>
          <Typography
            variant='h4'
            style={{
              color: "ghostwhite",
              width: 'auto',
              align: 'center',
              textAlign: "center",
              padding: '1.5em',
              margin: 'auto',
              fontSize: '1.5rem',
              textShadow: "2px 2px 1px black",
            }}>
  		      Appfluencer Predictive Targeting
  		      <br></br>
  		      Easy as one, two, three!
  		    </Typography>
        </div>
      </Container>
    </ThemeProvider>
	)
}

export default PricingSubTitleContainer1;
