// import AccessTargeting from '../../Buttons/AccessTargeting';
import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { createTheme } from '@mui/material/styles';
import { responsiveFontSizes } from '@mui/material/styles';

let theme = createTheme();
theme = responsiveFontSizes(theme);

function CallToActionContainer() {
  return (
    <ThemeProvider theme={theme}>
		  <div className='call-to-action-container'
  	  	style={{
  	  	  display: 'flex',
  	  	  justifyContent: 'center',
  	  	  textAlign: 'center',
  	  	  alignItems: 'center',
  	  	  margin: 'auto',
  	  	}}>
			<Typography
        variant='h4'
        align='center'
          style={{
            justifyContent: 'center',
            textAlign: 'center',
            alignItems: 'center',
            margin: 'auto',
            padding: '1em',
		      }}>
		      Get Predictive Targeting
		    </Typography>
      </div>
    </ThemeProvider>
	)
}

export default CallToActionContainer
