import React, { Fragment } from 'react';

import Background from '../components/Container/Home/Background';
import MyTheme from '../themes/MyTheme';
import { ThemeProvider } from '@mui/material/styles';

export default class Home extends React.Component {
  render() {
    return (
      <ThemeProvider theme={MyTheme}>
        <Fragment>
         <div className="Home">
           <Background />
           </div>
         </Fragment>
      </ThemeProvider>
	  );
  }
}
