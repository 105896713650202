import * as React from 'react';

import { ThemeProvider, createTheme } from '@mui/material/styles';

import { Container } from '@mui/material';
import { Typography } from '@mui/material';
import { responsiveFontSizes } from '@mui/material/styles';

let theme = createTheme();
theme = responsiveFontSizes(theme);

function TitleContainer() {
  return (
    <ThemeProvider theme={theme}>
      <Container>
      <div className='case-studies-title'>
        <Typography
          variant='h3'
          align='center'
          style={{
            justify: 'center',
            color: "ghostwhite",
            fontFamily: 'Roboto, sans-serif',
            fontWeight: '900',
            textTransform: 'capitalize',
            paddingTop: '1.350em',
            padding: '0.625em',
            margin: 'auto',
            textShadow: "2px 2px 1px black",
          }}>
          Appfluencer Predictive Targeting
			    </Typography>
        </div>
      </Container>
    </ThemeProvider>
	)
}

export default TitleContainer
