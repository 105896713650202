import MyTheme from '../../../themes/MyTheme';
import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { createTheme } from '@mui/material/styles';
import { responsiveFontSizes } from '@mui/material/styles';

let theme = createTheme();
theme = responsiveFontSizes(theme);

function TargetingToday1() {
  return (
    <ThemeProvider theme={MyTheme}>
      <ThemeProvider theme={theme}>
        <div className='what-we-do-container'
          type="container"
          alignitems='center'
          width='100%'
        >
          <Typography
            variant='h4'
            align='center'
            style={{
              justify: 'center',
              margin: 'auto',
              padding: 'auto',
              fontFamily: 'Roboto, sans-serif',
              fontSize: '1.375rem',
              fontWeight: 'normal',
              color: '#0d47a1',
              textAlign: 'center',
              width: '80%',
            }}>
              The game has changed. The old ways of targeting are long gone.
              Reach your audience using predictive targeting.
            <br /><br />
            Read the Executive Summary and learn more.
          </Typography>
        </div>
      </ThemeProvider>
    </ThemeProvider>
  )
}

export default TargetingToday1;
