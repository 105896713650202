import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { createTheme } from '@mui/material/styles';
import { responsiveFontSizes } from '@mui/material/styles';

let theme = createTheme();
theme = responsiveFontSizes(theme);

function SubTitleContainer3() {
	return (
		<ThemeProvider theme={theme}>
    <div className='subtitle-container-3'>
			<Typography
        variant="h4"
        component="h4"
        align="center"
        sx={{
			  	display: 'flex',
			  	padding: '1em',
			  	margin: 'auto',
			  	textAlign: 'center',

			  	fontWeight: '400',
			  	width: '100%',
          overflowWrap: 'break-word',
          textTransform: 'capitalize',
			  }}>
          On average, predictive targeting yielded 3x Faster optimization times having a direct impact on campaign performance.
			</Typography>
      </div>
    </ThemeProvider>
	)
}

export default SubTitleContainer3;
