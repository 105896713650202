import React, { Component, Fragment } from 'react';

import MyTheme from '../themes/MyTheme';
import { ThemeProvider } from '@mui/material/styles';

export default class GimmeShelter extends Component {

	render() {
    return (
      <ThemeProvider theme={MyTheme}>
			  <Fragment>
          <div className='30day-study-download'
            maxwidth='false'
            style={{
              justifyContent: 'center',
              minHeight: '100%',
              padding: 'auto',
              margin: 'auto'
            }}>
            <div className='30day-download'
	            maxwidth='false'
	            style={{
	              display: 'flex',
	              flexDirection: 'row',
	              padding: 0,
	              margin: 0,
	            }}>
			    		<iframe src="https://shared2you.wufoo.com/forms/kw1gfax1b31pze/" title='The Future of Work: Leveraging AI and Predictive Targeting to Create Highly Effective Mobile Campaigns' style={{ frameborder: 0, border: 0, width:1366, height:983, paddingBottom: 0, marginBottom: 0}} allowFullScreen={true} />
            </div>
          </div>
        </Fragment>
      </ThemeProvider>
		)
	}
}
