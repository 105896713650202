import MyTheme from '../../../themes/MyTheme';
import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { createTheme } from '@mui/material/styles';
import { responsiveFontSizes } from '@mui/material/styles';

let theme = createTheme();
theme = responsiveFontSizes(theme);

function HowWeDoPrivacyContainer() {
  return (
    <ThemeProvider theme={MyTheme}>
      <ThemeProvider theme={theme}>
      <div className='how-we-do-container'>
        <Typography
          variant='h4'
          align='center'
          style={{
            justify: 'center',
            textAlign: 'center',
            margin: 'auto',
            padding: '1em',
            fontFamily: 'Roboto, sans-serif',
            fontSize: '1em',
            width: '80%',
          }}>
            Predictive Targeting is privacy compliant and can be used in your mobile ad campaigns because it does not contain personal or device information, and can be used on Social, SDK and DSP networks.
        </Typography>
      </div>
      </ThemeProvider>
    </ThemeProvider>
  )
}

export default HowWeDoPrivacyContainer;
