import * as React from 'react';

import { ThemeProvider, createTheme } from '@mui/material/styles';

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

const theme = createTheme({
  palette: {
    primary: {
      light: '#5472d3',
      main: '#0d47a1',
      dark: '#002171',
      contrastText: '#ffffff'
    },
    secondary: {
      light: '#D3B554',
      main: '#A1660D',
      dark: '#714F00',
      contrastText: '#000000'
    },
  }
});

export default function AppBarButtons() {

  return (
    <ThemeProvider theme={theme}>
      <Stack direction="row" spacing={2}>
        <Button
       //   variant='contained'
          disableElevation
          size="medium"
          sx={{
            fontSize: '0.750rem',
            color: 'inherit',
            '&:hover': {
              backgroundColor: 'primary',
              color: 'primary',
            }
          }}
          onClick={(e) => {
            e.preventDefault();
            window.location.href = '/freetrial';
          }}>
          Free Trial
        </Button>
        <Button
        //  variant='contained'
          disableElevation
          sx={{
            fontSize: '0.750rem',
            color: 'inherit',
            '&:hover': {
              backgroundColor: 'primary',
              color: 'primary',
            }
          }}
          onClick={(e) => {
            e.preventDefault();
            window.location.href = 'https://calendly.com/targeting-demo/20-minute-qa?utm_source=demo_request&utm_medium=web_site&utm_campaign=appfluencer&utm_content=appbar&utm_term=demo';
          }}>
          Schedule Demo
        </Button>
        <Button
        //  variant='contained'
          disableElevation
          sx={{
            fontSize: '0.750rem',
            color: 'inherit',
            '&:hover': {
              backgroundColor: 'primary',
              color: 'primary',
            }
          }}
          onClick={(e) => {
            e.preventDefault();
            window.location.href = 'https://payments.appfluencer.com/p/login/7sIcPl3ip6PQgeI5kk';
          }}>
           Account
        </Button>
      </Stack>
    </ThemeProvider>
  );
}
