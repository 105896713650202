import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { createTheme } from '@mui/material/styles';
import { responsiveFontSizes } from '@mui/material/styles';

let theme = createTheme();
theme = responsiveFontSizes(theme);

function FreeTrialRequestFormIntro() {
  return (
    <ThemeProvider theme={theme}>
      <div className='data-request-form-container'>
        <Typography
          variant='h4'
          align='center'
          style={{
            color: 'ghostwhite',
            fontFamily: 'Roboto',
            margin: 'auto',
            padding: 'auto',
            width: '80%',
            overflowWrap: 'break-word',
            fontSize: '1.25rem',
        }}>
        Submit this form to see if you meet the requirements for a free trial. Have questions, Contact Us. Thank you!
        <br /><br />
        </Typography>
      </div>
    </ThemeProvider>
  )
}

export default FreeTrialRequestFormIntro;
