import MoreInfoButton1 from '../../Buttons/MoreInfoButton1';
import MyTheme from '../../../themes/MyTheme';
import React from 'react';
import { ThemeProvider } from '@mui/material/styles';

function MoreInfoContainer1() {
  return (
    <ThemeProvider theme={MyTheme}>
      <div type='container'
        align='center'
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          width: '10em',
          color: '#FFFFFF',
          backgroundColor: '#FFFFFF',
          size: 'large',
        }}>
          <MoreInfoButton1 />
        </div>
      </ThemeProvider>
    )
  }

export default MoreInfoContainer1;
