import LearnMoreButton1 from '../../Buttons/LearnMoreButton1a';
import MyTheme from '../../../themes/MyTheme';
import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import { responsiveFontSizes } from '@mui/material/styles';
let theme = createTheme();
theme = responsiveFontSizes(theme);

function LearnMoreContainer1() {
  return (
    <ThemeProvider theme={MyTheme}>
      <ThemeProvider theme={theme}>
        <div type='container'
          align='center'
          padding='auto'
          margin='auto'
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            color: 'white',
            backgroundColor: '#FFFFFF',
            size: 'large',
          }}>
          <LearnMoreButton1 />
        </div>
      </ThemeProvider>
    </ThemeProvider>
  )
}

export default LearnMoreContainer1;
