import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { createTheme } from '@mui/material/styles';
import { responsiveFontSizes } from '@mui/material/styles';

let theme = createTheme();
theme = responsiveFontSizes(theme);

function TitleContainer() {
  return (
    <ThemeProvider theme={theme}>
      <div className='case-studies-title'>
        <Typography
          variant='h1'
          align='center'
          style={{
            justify: 'center',
            fontFamily: 'Roboto, sans-serif',
            margin: 'auto',
            paddingTop: '1em',
            padding: 'auto',
            fontWeight: '900',
            textTransform: 'capitalize',
            textShadow: "2px 2px 1px black",
            width: '100%',
          }}>
          Predictive Targeting Overcomes
          Facebook Targeting
          Limitations
			  </Typography>
		  </div>
    </ThemeProvider>
	)
}

export default TitleContainer
