import { ThemeProvider, createStyles, makeStyles } from '@mui/styles';

import CaseStudyContainer from './CaseStudyContainer';
import CaseStudyIntro from './CaseStudyIntro';
import CaseStudyIntro1 from './CaseStudyIntro1';
import CaseStudyIntro1a from './CaseStudyIntro1a';
import CaseStudyIntro1b from './CaseStudyIntro1b';
import CaseStudyIntro1c from './CaseStudyIntro1c';
import CaseStudyIntro1d from './CaseStudyIntro1d';
import CaseStudyIntro2a from './CaseStudyIntro2a';
import CaseStudySummary from './CaseStudySummary';
import CaseStudySummary1 from './CaseStudySummary1';
import CaseStudySummary1a from './CaseStudySummary1a';
import CaseStudySummary1b from './CaseStudySummary1b';
import CaseStudySummary1c from './CaseStudySummary1c';
import CaseStudySummary1d from './CaseStudySummary1d';
import CaseStudySummary1e from './CaseStudySummary1e';
import CaseStudySummary5 from './CaseStudySummary5';
import { Grid } from '@mui/material';
import MyTheme from '../../../themes/MyTheme';
import React from 'react';
import TargetingBackground from './TargetingBackground';
import TargetingToday2 from './TargetingToday2';
import TitleContainer1 from './TitleContainer1';

const useStyles = makeStyles((MyTheme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: MyTheme.spacing(2),
      textAlign: 'center',
      color: MyTheme.palette.text.secondary,
    },
  }),
);

export default function FullWidthGrid() {
  const classes = useStyles();
  return (
    <ThemeProvider theme={MyTheme}>
      <div className={classes.root}>
        <Grid
          container
          spacing={2}
          alignitems='center'
          width='100%'
        >
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <TitleContainer1 />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <TargetingToday2 />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CaseStudyIntro />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CaseStudySummary />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CaseStudyIntro1 />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CaseStudySummary1 />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CaseStudyIntro1a />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CaseStudySummary1a />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CaseStudyIntro1b />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CaseStudySummary1b />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CaseStudyIntro1c />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CaseStudySummary1c />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CaseStudyIntro2a />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CaseStudySummary5 />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CaseStudyIntro1d />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CaseStudySummary1d />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CaseStudySummary1e />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CaseStudyContainer />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <TargetingBackground />
          </Grid>
        </Grid>
      </div>
    </ThemeProvider>
  );
}
