import CaseStudyIntro3 from './CaseStudyIntro3';
import CaseStudySummary2 from './CaseStudySummary2';
import LearnMoreContainer1 from './LearnMoreContainer1';
import MyTheme from '../../../themes/MyTheme';
import PricingSubTitleContainer1 from './PricingSubTitleContainer1';
import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import background from '../../../Assets/img/northern_lights.jpg'

function TargetingBackground() {
  return (
    <ThemeProvider theme={MyTheme}>
    <div style={{
      backgroundImage: `url(${background})`,
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      minHeight: '100em 100vh',
      minWidth: '100% 25em',
      margin: 'auto',
      padding: '2em',
      alignContent: 'center',
      }}
      >
      <CaseStudySummary2 />
      <CaseStudyIntro3 />
      <LearnMoreContainer1 />
      <PricingSubTitleContainer1 />
      </div>
    </ThemeProvider>
  )
}

export default TargetingBackground
