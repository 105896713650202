import Button from '@mui/material/Button'
import MyTheme from '../../themes/MyTheme';
import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((MyTheme) => ({
  root: {
  '& > *': {
    margin: MyTheme.spacing(1),
    padding: MyTheme.spacing(2),
    width: '18ch',
    height: '6ch',
    border: '0.125em solid #FFFFFF',
    borderRadius: '0.625em',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#fff',
      color: '#fff',
      border: '0.125em solid black',
    },
  },
},
}));

export default function GetCaseStudy() {
  const classes = useStyles();

  return (
    <ThemeProvider theme={MyTheme}>
      <div className={classes.root}>
        <Button
          variant='contained'
          href='#stairway-to-heaven-button'
          onClick={(e) => {
              e.preventDefault();
              window.location.href = 'whitepaper/';
          }}>
          Download
        </Button>
      </div>
    </ThemeProvider>

  );
}
