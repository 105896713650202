import './FreeTrialRequestForm.css';
import "react-toastify/dist/ReactToastify.css";

import React from 'react';
import emailjs from '@emailjs/browser';
import { toast } from "react-toastify";

export default function FreeTrialRequestForm() {
  // const form = useRef();
  const notify = () =>
    toast.info("sent 2", {
      className: "sent",
      draggable: true,
      position: toast.POSITION.TOP_CENTER
    });

  toast.error("error !");
  toast.success("success!");
  // toast.info("info!")
  // toast.warn("warn!")

  function sendEmail(e) {
    e.preventDefault();

    const cfg = {
      service_id: "service_26c4nst",
      template_id: "template_434ta8d",
      user_ID: "E5F2l4Sr7M2va11xS"
    };

    const params = {
      firstName: e.target.firstName.value,
      lastName: e.target.lastName.value,
      company: e.target.company.value,
      company_domain: e.target.company_domain.value,
      google_play_store_url: e.target.google_play_store_url.value,
      itunes_url: e.target.itunes_url.value,
      email: e.target.email.value,
      phone: e.target.phone.value,
      reply_to: e.target.reply_to.value,
      message: e.target.message.value,
    };
    console.log(params.firstName);
    console.log(params.lastName);
    console.log(params.company);
    console.log(params.company_domain);
    console.log(params.google_play_store_url);
    console.log(params.itunes_url);
    console.log(params.email);
    console.log(params.phone);
    console.log(params.reply_to);
    console.log(params.message);

    emailjs.send(cfg.service_id, cfg.template_id, params, cfg.user_ID).then(
      (result) => {
        notify();
      },
      (error) => {
      }
    );
    e.target.reset();
  }

  return (
    <div>
      <form name="tn" id="tn" className="data-request-form" onSubmit={sendEmail}>
        <label >First Name</label>
        <input type="text" name="firstName" id="firstName" />

        <label >Last Name</label>
        <input type="text" name="lastName" id="lastName" />

        <label>Company</label>
        <input type="text" name="company" id="company" />


        <label>Company Domain</label>
        <input type="text" name="company_domain" id="company_domain" />

        <label>Google Play Store Url</label>
        <input type="text" name="google_play_store_url" id="google_play_store_url" />

        <label>iTunes Url</label>
        <input type="text" name="itunes_url" id="itunes_url" />

        <label>email</label>
        <input type="text" name="email" id="email" />

        <label>phone</label>
        <input type="text" name="phone" id="phone" />

        <label>Message</label>
        <input type="text" name="message" id="message" />

        <label>Reply_to</label>
        <input type="text" name="reply_to" id="reply_to" />

        <input type="submit" value="Create My Future" />
      </form>
    </div>
  )
}
