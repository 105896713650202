import MyTheme from '../../../themes/MyTheme';
import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import { responsiveFontSizes } from '@mui/material/styles';

let theme = createTheme();
theme = responsiveFontSizes(theme);

function CaseStudySummary1c() {
  return (
    <ThemeProvider theme={MyTheme}>
      <ThemeProvider theme={theme}>
		    <div className='text-container-cs'
  	    	style={{
  	    	  textAlign: 'center',
  	    	  fontFamily: 'Roboto, sans-serif',
  	    	  fontSize: '1.015em',
            fontWeight: '400',
            color: '#0d47a1',
  	    	  margin: 'auto',
  	    	  padding: 'auto',
  	    	  width: '80%',
		    	}}>
          Using information from the three day test, the 30 day test was started. It was found that Appfluencer's Predictive Targeting was key in focusing media spend to help target the customers ad placements so they could reach new customers.
          <br /><br />
          The use of predictive targeting resulted in a significant increase in pLTV, ROAS, and retention. In addition, Predictive targeting was responsible for getting the customers message in front of a VIP customer.
        </div>
      </ThemeProvider>
    </ThemeProvider>
	)
}

export default CaseStudySummary1c;
