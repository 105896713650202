import PredictiveTargetingButton from '../../Buttons/PredictiveTargetingButton';
import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import { responsiveFontSizes } from '@mui/material/styles';

let theme = createTheme();
theme = responsiveFontSizes(theme);

function LearnMoreContainer1() {
  return (
    <ThemeProvider theme={theme}>
      <div type='container'
        className='learn-more-container1'
        align='center'
        padding='auto'
        margin='auto'
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          color: 'white',
          backgroundColor: '#FFFFFF',
          size: 'large',
        }}>
          <PredictiveTargetingButton />
      </div>
    </ThemeProvider>
  )
}

export default LearnMoreContainer1;
