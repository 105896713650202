import React, { useState } from 'react';

import InputBase from '@mui/material/InputBase';
import Link from '@mui/material/Link';
import SearchIcon from '@mui/icons-material/Search';
import { Typography } from '@mui/material';
import WebSiteContent from './WebsiteContent';
import { alpha } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 0),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
  autocompleteResults: {
    position: 'absolute',
    left: 0,
    right: 0,
    backgroundColor: theme.palette.common.white,
    color: 'black',
    fontFamily: 'Roboto open-sans',
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[3],
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
    maxHeight: '200px',
    overflowY: 'auto',
    zIndex: 10, // Ensure the results are on top of other elements
  },
  resultItem: {
    title: {

      fontSize: '0.875rem',
    },
  padding: theme.spacing(0.5, 1),
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
    cursor: 'pointer',
  },
  '& h5, & h6': {
    margin: 0,
  },
    '& h5': {
    fontFamily: 'Roboto open-sans',
    fontSize: '1.05rem',
    fontWeight: 500,
    textAlign: 'left',
  },
  '& h6': {
    fontSize: '0.875rem',
    fontWeight: 400,
    },
    '& Typography': {
      textAlign: 'left',
      fontFamily: 'Roboto open-sans',
    }
},
}));

const SearchBar = () => {
  const classes = useStyles();
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredResults, setFilteredResults] = useState([]);

  const handleChange = (event) => {
    const searchTerm = event.target.value;
    setSearchTerm(searchTerm);
    if (searchTerm) {
      const results = WebSiteContent.map((page) => {
        const pageContent = page.content.split('. ');
        const searchResults = pageContent.filter((sentence) =>
          sentence.toLowerCase().includes(searchTerm.toLowerCase())
        );
        if (searchResults.length > 0) {
          return {
            id: page.id,
            title: page.title,
            path: page.path,
            searchResults,
          };
        }
        return null;
      });
      setFilteredResults(results.filter(Boolean));
    } else {
      setFilteredResults([]);
    }
  };

  return (
    <div className={classes.search} key={classes.id}>
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>
      <InputBase
        type="search"
        value={searchTerm}
        onChange={handleChange}
        placeholder="Search…"
        sx={{
          color: 'white',
          paddingLeft: 'calc(1em + 32px)',
        }}
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        inputProps={{ 'aria-label': 'searchTerm' }}
      />
      {searchTerm && (
        <div className={classes.autocompleteResults}>
          {filteredResults.map((result) => (
            <div key={result.id} className={classes.resultItem}>
              <Typography style={{fontSize: '0.9rem'}}>
                <Link href={result.path}>{result.title}</Link>
              </Typography>
              {result.searchResults.map((sentence) => (
                <Typography variant='h6'
                  key={sentence}>{sentence}
                </Typography>
              ))}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SearchBar;
