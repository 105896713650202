// import '../components/Footer/Footer.css';

import React, { Fragment } from 'react';

import MyTheme from '../themes/MyTheme';
import SubscriptionDataRequestBackground from '../components/Container/SubscriptionDataRequestForm/SubscriptionDataRequestBackground';
import { ThemeProvider } from '@mui/material/styles';

export default class SubscriberContact extends React.Component {
  render() {
    return (
      <ThemeProvider theme={MyTheme}>
        <Fragment>
          <div className="subscriber-request">
            <SubscriptionDataRequestBackground />
          </div>
        </Fragment>
      </ThemeProvider>
	  );
  }
}
