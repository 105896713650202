import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import { responsiveFontSizes } from '@mui/material/styles';

let theme = createTheme();
theme = responsiveFontSizes(theme);

function ImageContainer1() {
  return (
    <ThemeProvider theme={theme}>
		  <div
		  	className='image-container-1'
		  	display='flexblock'
		  	direction='row'
		  	padding='auto'
        margin='auto'
        width='100%'
		  >
		  	<span
		  		style={{
		  			padding: 'auto',
		  			margin: 'auto',
		  		}}>
		  		<img
			  		src='https://storage.googleapis.com/appfluencer_com/2017/07/1024x500_2_v2-736x414.jpg'
			  		alt='wordscapes'
			  		width='100%'
			  		justifycontent='center'
			  	>
        	</img>
    	  </span>
      </div>
    </ThemeProvider>
	)
}

export default ImageContainer1
