import { createStyles, makeStyles } from '@mui/styles';

// import CaseStudyContainer from './CaseStudyContainer';
import ContactIntro from './ContactIntro';
import Grid from '@mui/material/Grid';
import HowWeDoPredictiveContainer from './HowWeDoPredictiveContainer';
import HowWeDoPrivacyContainer from './HowWeDoPrivacyContainer';
import MyTheme from '../../../themes/MyTheme';
// import QuoteContainer from './QuoteContainer';
// import QuoteContainer2 from './QuoteContainer2';
import React from 'react';
import TargetingBackground1 from '../Targeting/TargetingBackground1';
import TitleContainer from './TitleContainer';

// import TitleContainer1 from './TitleContainer1';

const useStyles = makeStyles((theme: MyTheme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: MyTheme.spacing(0),
      textAlign: 'center',
      color: MyTheme.palette.text.secondary,
    },
  }),
);

export default function AboutGrid() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={1} sm={1} md={1} lg={2}>
          </Grid>
        <Grid item xs={10} sm={10} md={10} lg={8}>
          <TitleContainer />
          </Grid>
        <Grid item xs={1} sm={1} md={1} lg={2}>
          </Grid>
        <Grid item xs={1} sm={1} md={1} lg={2}>
          </Grid>
        <Grid item xs={10} sm={10} md={10} lg={8}>
          <HowWeDoPredictiveContainer />
          </Grid>
        <Grid item xs={1} sm={1} md={1} lg={2}>
          </Grid>
        <Grid item xs={1} sm={1} md={1} lg={2}>
          </Grid>
        <Grid item xs={10} sm={10} md={10} lg={8}>
          <TargetingBackground1 />
          </Grid>
        <Grid item xs={1} sm={1} md={1} lg={2}>
          </Grid>
        <Grid item xs={1} sm={1} md={1} lg={2}>
          </Grid>
        <Grid item xs={10} sm={10} md={10} lg={8}>
        <HowWeDoPrivacyContainer />
          </Grid>
        <Grid item xs={1} sm={1} md={1} lg={2}>
          </Grid>
        <Grid item xs={1} sm={1} md={1} lg={2}>
          </Grid>
        <Grid item xs={10} sm={10} md={10} lg={8}>
          <ContactIntro />
        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={2}>
          </Grid>
        <Grid item xs={1} sm={1} md={1} lg={2}>
          </Grid>
        <Grid item xs={10} sm={10} md={10} lg={8}>
         <div className='spacer' sx={{paddingBottom: '10em'}}></div>
          </Grid>
        <Grid item xs={1} sm={1} md={1} lg={2}>
          </Grid>
      </Grid>
    </div>
  );
}
