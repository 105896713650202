import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { createTheme } from '@mui/material/styles';
import { responsiveFontSizes } from '@mui/material/styles';

let theme = createTheme();
theme = responsiveFontSizes(theme);

function SubscriptionDataRequestFormIntro() {
  return (
    <ThemeProvider theme={theme}>
      <div className='data-request-form-container'>
          <Typography
            variant='h4'
            align='center'
            style={{
              color: 'ghostwhite',
              fontFamily: 'Roboto',
              margin: 'auto',
              padding: 'auto',
              paddingBottom: '1.5em',
              overflowWrap: 'break-word',
              fontSize: '1.375rem',
              width: '80%',
          }}>
          If you haven't provided this information yet,
          please complete the form below to start
          the onboarding process. If you need assistance,
          please <a href='mailto:support@appfluencer.com?subject=Onboarding Assistance' style={{ color: 'ghostwhite' }}>Email Support</a>
          </Typography>
      </div>
    </ThemeProvider>
  )
}

export default SubscriptionDataRequestFormIntro;
